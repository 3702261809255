import { MDBBtn } from "mdbreact";
import React from "react";
import CardComponent from "./CardComponent";

class ViewMoreComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: false,
    };
  }

  handleClick() {
    this.setState({ showMore: true });
  }

  render() {
    const list = this.props.projectArray;
    const numberOfItems = this.state.showMore ? list.length : 9;
    // console.log(this.props.viewMoreEnabled)
    // console.log(numberOfItems);

    const returnButton = (props) => {
      console.log(list.length >= 9);

      if ((!(numberOfItems === list.length) && list.length >= 9) === true) {
        return (
          <MDBBtn
            color="secondary"
            style={{
              marginBottom: "3rem",
            }}
            className="mx-auto"
            onClick={() => this.handleClick()}
          >
            View More
          </MDBBtn>
        );
      } else {
        return <></>;
      }
    };

    return (
      <div>
        <CardComponent
          start={0}
          end={numberOfItems}
          projectType={this.props.projectType}
        />
        {returnButton(this.props.viewMoreEnabled)}
      </div>
    );
  }
}

export default ViewMoreComponent;
